:root {
  --mainColor: #fcebc4;
  --mainColor2: #c5a263;
  --mainColor3: #673913;
  --mainColorBlack: #1e1e1c;
  --textColor: #1e1e1c;
  --textColorWhite: #ffffff;
  --backgroundColor: #F8F8F8;
  zoom: 1;
}

.floating-button {
position: fixed;
bottom: 20px; /* Adjust as needed */
right: 20px; /* Adjust as needed */
z-index: 9999; /* Ensure it's in front of other elements */
background-color: #000000; /* Button background color */
color: white; /* Button text color */
width: 70px; /* Button width */
height: 70px; /* Button height */
border-radius: 50%; /* Make it circular */
font-size: 24px; /* Button icon size */
border: none; /* Remove border */
cursor: pointer; /* Show pointer on hover */
}


* {
font-family: 'Walkway Bold';
src: url('./fonts/Walkway-Bold.ttf') format('truetype');  
}

/* NAVBAR */
.principalNavbar {
background-image: url("./images/imagen-fondo-proveedor.png");
background-repeat: no-repeat;
background-size: cover;
font-size: 14px;
}

ol, ul, dl {
margin-top: 0;
margin-bottom: 3rem;
}

.navbarMain {
font-size: 14px;
}

.navbarMain2 {
font-size: 14px;
text-align: center;
}

.centerNav {
text-align: center;
padding: 0px;
}

.navbarMain-logo {
  width:15vw;
  margin-right:5vw;
}

/* MAIN */
.desktop {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100vw;
  font-family: 'Walkway Bold', sans-serif;
  min-height: 100vh;
  flex-grow: 1;
}

@font-face {
  font-family: 'Walkway Bold';
  src: url('./fonts/Walkway-Bold.ttf') format('truetype'); 
  font-weight: bold;
  font-style: normal;
}

.desktop .div {
  background-color: #ffffff;
  position: relative;
  width: 100vw;
}

.desktop .overlap-group {
  height: 664px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100vw;
}

.desktop .imagen-fondo {
  height: 378px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100vw;
}

.desktop .text-wrapper {
  color: #d5c25d;
  font-family: 'Walkway Bold', sans-serif;
  font-size: 40px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 172px;
}

.desktop .button {
  all: unset;
  align-items: center;
  color: white;
  background-color: #0000008f;
  border: 2px solid;
  border-color: #e3e570;
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  height: 54px;
  justify-content: center;
  padding: 12px 24px;
  position: absolute;
  top: 243px;
  width: 314px;
}

/*.desktop .buttonSec {
  all: unset;
  align-items: center;
  color: white;
  background-color: #0000008f;
  border: 2px solid;
  border-color: #e3e570;
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  height: 54px;
  justify-content: center;
  padding: 12px 24px;
  position: absolute;
  top: 243px;
  width: 314px;
}*/

.desktop .button-2 {
  all: unset;
  box-sizing: border-box;
  color: #ccd787;
  font-family: 'Walkway Bold', sans-serif;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
  width: fit-content;
}

.desktop .proveedor {
  align-items: flex-start;
  display: inline-flex;
  gap: 10px;
  position: absolute;
  top: 115px;
}

.desktop .rectangle {
  background-color: #d5c25d;
  box-shadow: 0px 4px 4px #00000040;
  height: 549px;
  position: relative;
  width: 500px;
}

.desktop .imagen {
  height: 440px;
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 530px;
}

.desktop .titulo-experiencia {
  color: black;
  font-family: 'Walkway Bold', sans-serif;
  font-weight: 300;
  font-size: 34px;
  left: 53px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 450px;
  width: 414px;
}

.desktop .informacion-basica {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  position: absolute;
  top: 424px;
}

.desktop .frame {
  align-items: center;
  display: flex;
  gap: 10px;
  height: 60px;
  padding: 10px 0px;
  position: relative;
  width: 420px;
}

.desktop .text-wrapper-2 {
  align-self: stretch;
  color: #000000;
  font-family: 'Montserrat',Helvetica,Arial,Lucida,sans-serif;;
  font-weight: 300;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
  width: 420px;
}

.desktop .pepicons-pencil {
  height: 34px;
  left: 126px;
  position: absolute;
  top: 424px;
  width: 34px;
}

.desktop .iconTxt {
  height: 32px;
  width: 32px;
}

.desktop .octicon-person {
  height: 34px;
  left: 126px;
  position: absolute;
  top: 484px;
  width: 34px;
  display: none;
}

.desktop .formkit-time {
  height: 34px;
  left: 126px;
  position: absolute;
  top: 519px;
  width: 34px;
  
}

.desktop .codicon-settings {
  height: 34px;
  left: 126px;
  position: absolute;
  top: 614px;
  width: 34px;
}

.desktop .texto-experiencia {
  color: #000000;
  font-family: 'Montserrat',Helvetica,Arial,Lucida,sans-serif;;
  font-weight: 300;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  text-align: justify;
  width: 530px;
}

.desktop .overlap {
  height: 462px;
  position: absolute;
  width: 460px;
}

.desktop .imagen-SE {
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 42px;
  
}

.desktop .rectangle-2 {
  border: 4px solid;
  border-color: #000000;
  height: 420px;
  left: 40px;
  position: absolute;
  top: 0;
  width: 420px;
}

.desktop .p {
  color: #000000;
  font-family: 'Walkway Bold', sans-serif;
  font-size: 32px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 38px;
  position: absolute;
  text-align: center;
  top: 869px;
  width: 420px;
}

.fondo-p {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  width: 100%;
}

.fondo-p .imagen-p {
  height: 100dvh;
  width: 100vw;
  position: relative;
}

.fondo-p .horizontal-p {
  align-items: center;
  background-color: #d5c25dcc;
  display: flex;
  gap: 10px;
  height: 337px;
  left: 70px;
  padding: 10px 50px;
  position: absolute;
  top: 158px;
  width: 80vw;
}

.fondo-p .se-parte-de {
  color: #ffffff;
  font-family: 'Walkway Bold', sans-serif;
  font-size: 40px;
  font-weight: 400;
  height: 260px;
  letter-spacing: 0;
  line-height: 50px;
  position: relative;
  width: 230px;
}

.fondo-p .form-p {
  align-items: flex-start;
  display: inline-flex;
  gap: 10px;
  position: absolute;
  top: 70px;
  background-image: url("./images/imagen-fondo-proveedor.png");
  
}

.flTxt{
  color: whitesmoke;
}

.mb-3 {
padding: 1vh;
text-align: left;    
margin-bottom: 0rem !important;
padding-bottom: 1rem !important;
}

/* Search Bar*/
.box{
position: relative;
}

.input {
  padding: 10px;
  max-width: 60vw;
  min-width: 40vw;
  height: 3vh;
  background: none;
  border: 2px solid var(--mainColor);
  border-radius: 50px;
  box-sizing: border-box;
  outline: none;
  color: var(--mainColor);
  transition: .5s;
}
.box:hover input{
 max-width: 60vw;
 min-width: 40vw;
  background: #3b3640;
  border-radius: 10px;
  border: 2px solid var(--mainColor2);
  color: var(--mainColor2);
  box-shadow: 0 0 1em 0 var(--mainColor2);  

}
.box i{
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translate(-50%,-50%);
  font-size: 26px;
  color: var(--mainColor);
  transition: .2s;
}
.box:hover i{
  opacity: 0;
  z-index: -1;
}

/*ID NAVBAR TOP*/
.sessionID{
  position: absolute;
  top: 10px;
  right: 5vw;
}

.ButtonName {
  background-color: var(--textColorWhite);
  border: 0.2em solid var(--mainColor);
  color: var(--mainColor);
  display: contents;
  justify-content: center;
  align-items: center;
}

.ButtonName img{
  margin-top: 2vh;
  width: 3.5vh;
  border-radius: 50%;
  height: 3.5vh;
}

#ButonGO {
margin-top: 2vh;
margin-left: 10px;  
font-size: 1.3em;
background-color: var(--mainColor2);
border: 0.2em solid var(--mainColorBlack);
color: var(--mainColorBlack);
&:hover{
box-shadow: 0 0 1em 0 var(--mainColor2);  
background-color: var(--mainColorBlack);
border: 0.2em solid var(--mainColor2);
color: var(--mainColor2);
}
}

#ButtonLog {
  display: none;
  margin-top: 2vh;
  margin-left: 10px;
  font-size: 1.3em;
  background-color: var(--mainColorBlack);
  border: 0.2em solid var(--mainColor);
  color: var(--mainColor);
  &:hover{
    box-shadow: 0 0 1em 0 var(--mainColor2);  
    background-color: var(--mainColorBlack);
    border: 0.2em solid var(--mainColor2);
    color: var(--mainColor2);
  }
}

.buttonSec {
margin-left: 10px;
font-size: 1.3em;
background-color: var(--mainColorBlack);
border: 0.2em solid var(--mainColor);
color: var(--mainColor);
&:hover{
  box-shadow: 0 0 1em 0 var(--mainColor2);  
  background-color: var(--mainColorBlack);
  border: 0.2em solid var(--mainColor2);
  color: var(--mainColor2);
}
}

/* NAVBAR FILTROS */
#navbar-filtros{
padding: 2vw 2vw;
}


/*Ciudad y Modalidad*/
select {
text-align: center;
appearance: none;
font: inherit;
color: var(--mainColor);
background-color: var(--mainColorBlack);
border: 0 solid var(--mainColor);
&:focus {
  outline: none;
}
&:hover{
  background-color: var(--mainColorBlack);
  color: var(--mainColor2);
}
}

.vr{
color: var(--mainColor);
margin: 0vw 2vw 0vw 1vw;
} 

/* Otros botones */
.botonesUnicoFiltro {
border-radius: 1rem;
font: inherit;
font-size: 1.1rem;
padding: 0.5rem;
color: var(--mainColor);
background-color: var(--mainColorBlack);
border: 0.2em solid var(--mainColor);
display: inline-block;

  &:hover{
    box-shadow: 0 0 1em 0 var(--mainColor2);  
    background-color: var(--mainColorBlack);
    border: 0.2em solid var(--mainColor2);
    color: var(--mainColor2);
  }
}

.botonesFn{
  border-radius: 1rem;
  font: inherit;
  font-size: 1.1rem;
  padding: 0.5rem;
  color: var(--mainColorBlack);
  background-color: var(--mainColor2);
  border: 0.2em solid var(--mainColorBlack);
  display: inline-block;
  
    &:hover{
      box-shadow: 0 0 1em 0 var(--mainColor2);  
      background-color: var(--mainColorBlack);
      border: 0.2em solid var(--mainColor2);
      color: var(--mainColor2);
    }
  }

.botonSeleccion {
border-radius: 1rem;
font: inherit;
padding: 0.5rem;
color: var(--mainColor);
background-color: var(--mainColorBlack);
border: 0.2em solid var(--mainColor2);
&:hover{
  box-shadow: 0 0 1em 0 var(--mainColor3);  
  background-color: var(--mainColorBlack);
  border: 0.2em solid var(--mainColor3);
  color: var(--mainColor2);
}
}

.botonReset {
border-radius: 1rem;
font: inherit;
padding: 0.5rem;
color: var(--mainColor);
background-color: var(--mainColorBlack);
border: 0.2em solid var(--mainColor2);
&:hover{
  box-shadow: 0 0 1em 0 var(--mainColor3);  
  background-color: var(--mainColorBlack);
  border: 0.2em solid var(--mainColor3);
  color: var(--mainColor2);
}
}

.botonesUnicoFiltroCata {
box-shadow: 0 0 1em 0 var(--mainColor2);  
background-color: var(--mainColorBlack);
border: 0.2em solid var(--mainColor2);
color: var(--mainColor2);
}

.botonesUnicoFiltroCatering {
box-shadow: 0 0 1em 0 var(--mainColor2);  
background-color: var(--mainColorBlack);
border: 0.2em solid var(--mainColor2);
color: var(--mainColor2);
}

.botonesUnicoFiltroMasterClass {
box-shadow: 0 0 1em 0 var(--mainColor2);  
background-color: var(--mainColorBlack);
border: 0.2em solid var(--mainColor2);
color: var(--mainColor2);
}

.botonesUnicoFiltroRegalosCorporativos {
box-shadow: 0 0 1em 0 var(--mainColor2);  
background-color: var(--mainColorBlack);
border: 0.2em solid var(--mainColor2);
color: var(--mainColor2);
}

.botonesUnicoFiltroRestaurantes {
box-shadow: 0 0 1em 0 var(--mainColor2);  
background-color: var(--mainColorBlack);
border: 0.2em solid var(--mainColor2);
color: var(--mainColor2);
}

.botonesUnicoFiltroDeTemporada {
box-shadow: 0 0 1em 0 var(--mainColor2);  
background-color: var(--mainColorBlack);
border: 0.2em solid var(--mainColor2);
color: var(--mainColor2);
}

.botonesUnicoFiltroTallerdeCocina {
box-shadow: 0 0 1em 0 var(--mainColor2);  
background-color: var(--mainColorBlack);
border: 0.2em solid var(--mainColor2);
color: var(--mainColor2);
}

.botonesUnicoFiltroChefenCasa {
color: var(--mainColor2);
}

.loader-container {
width: 100%;
height: 100vh;
display: flex;
justify-content: center;
align-items: center;
position: fixed;
background: rgba(0, 0, 0, 0.834);
z-index: 1;
}


/* RELOAD PAGE */
.spinner {
width: 64px;
height: 64px;
border: 8px solid;
border-color: #3d5af1 transparent #3d5af1 transparent;
border-radius: 50%;
animation: spin-anim 1.2s linear infinite;
}

@keyframes spin-anim {
0% {
    transform: rotate(0deg);
}
100% {
    transform: rotate(360deg);
}
}

.itemsPage {
width: 100vw;
padding: 1vw ;
}

.ItemsInside {
  padding: 1vh 2vw;
}

@media(min-width: 1300px){
.cards {
  width: 380px;
  height: 450px;
}
}

@media(max-width: 1300px){
.cards {
  width: 320px;
  height: 400px;
}
}

.cards {
max-width: 100%; 
overflow: hidden;

&:hover{
  box-shadow: 0 0 1em 0 var(--mainColor2);  
  border: 0.5em solid var(--mainColor2);
  border-radius: 1em;
  color: var(--mainColor2);
  transition: 0.3s;
}
}

.cartaEspecifica {

height: 45vh;
max-width: 100%; /* Ensures the container does not exceed the viewport width */
overflow: hidden;

&:hover{
  box-shadow: 0 0 1em 0 var(--mainColor2);  
  border: 0.5em solid var(--mainColor2);
  border-radius: 1em;
  color: var(--mainColor2);
  transition: 0.3s;
}
}

.cardsImage {
/*height: 32vh;*/
width: 100%;
}

.cardsImageSec {
height: 32vh;
}

.cardsBody {
/*height: 8vh;
*/
height: 8vmax;
font-size: 3vw;
}

.backgroundWhite {
background-image: url("./images/whiteBG.png");
background-repeat: no-repeat;
background-size: cover;
}
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

.container {
max-width: 1800px;
margin: 0 auto;
display: flex;
justify-content: space-between;
align-items: center;
}

.menu-icon {
display: none;
}

.nav-elements ul {
display: flex;
flex-wrap: wrap;
}

.nav-elements ul li:not(:last-child) {
margin-right:1vw;
}

.nav-elements ul a {
font-size: 16px;
font-weight: 400;
text-decoration: none;
}

.nav-elements ul a.active {
font-weight: 500;
position: relative;
}

.nav-elements ul a.active::after {
content: "";
position: absolute;
bottom: -4px;
left: 0;
width: 100%;
height: 2px;
}


@media (max-width: 768px) {
  .nav-elements ul li:not(:last-child) {
    margin-right: 30px;
  }
  .ItemsInside{
      margin-left: 10vw;
  }
  .vistaCELULARARRIBA{
     top: 10px;
  }
}

@media (max-width: 768px) {
.menu-icon {
  display: block;
  cursor: pointer;
}

.nav-elements {
  position: absolute;
  right: 0;
  top: 20px;
  width: 0px;
  transition: all ease-in;
  overflow: hidden;
}

.nav-elements.active {
  width: 270px;
}

.nav-elements ul {
  display: flex;
  flex-direction: column;
}

.nav-elements ul li {
  margin-right: unset;
  margin: 10px;
}
}

.offcanvas-body {
margin:0px !important;
padding: 0px;
text-align: center;
}

.offcanvas.offcanvas-end {
width: 0px;

}

@media (max-width: 1000px){
.desktop .proveedor {
  display: none;
}
.desktop .rectangle-2{
  display: none;
}
.desktop .texto-experiencia {
  top: 1097px;
  left: 10vw;
  right: 10vw;
  width: 80vw;
}
.desktop .imagen-SE{
  height: 320px;
  width: 100vw;
  display: none;
}
.desktop .p {
  display: none;
}
.desktop .overlap {
  top: 700px;
}
}

@media (min-width: 1500px){
.desktop .texto-experiencia {
  top: 797px; /*997*/
  right: 15vw;
}
.desktop .p {
  right: 15vw;
}
.desktop .overlap {
  top: 838px;
  left: 15vw;
}
.desktop .imagen-SE{
  height: 420px;
}
.desktop .imagen-SE{
  width: 420px;
}
}


@media (max-width: 1000px){
.fondo-p .horizontal-p {
  display: none;
}
.fondo-p .se-parte-de {
  display: none;
}
.desktop .text-wrapper {
  left: 10vw;
}
.desktop .button {
  left: 10vw;
}
.desktop .informacion-basica {
  left: 14vw;
}
.desktop .pepicons-pencil {
  left: 6vw;
}
.desktop .octicon-person {
  left: 6vw;
}
.desktop .formkit-time {
  left: 6vw;
}
.desktop .codicon-settings {
  left: 6vw;
}

}

@media (min-width: 1000px){
.fondo-p .form-p {
  left: 400px;
}
.desktop .text-wrapper {
  left: 15vw;
}
.desktop .button {
  left: 15vw;
}
.desktop .proveedor {
  right: 15vw;
}
.desktop .informacion-basica {
  left: 18vw;
}
.desktop .pepicons-pencil {
  left: 14vw;
}

.desktop .octicon-person {
  left: 14vw;
}

.desktop .formkit-time {
  left: 14vw;
}

.desktop .codicon-settings {
  left: 14vw;
}
}

.nav-elements ul li:not(:last-child) {
margin-right: 0.7vw;
margin-left: 0.7vw;
}

.navbarToggle{
position: absolute;
top: -90px;
right: 3vw;
background-color: #c5a263;
}

.imgePreview {
height: 450px;
width: 450px;
}